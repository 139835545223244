<template>
    <vs-row class="h-100 w-100 flex w-full bg-img">
        <vs-col class="d-flex justify-content-center align-items-center flex-column text-center">
            <img src="@/assets/images/pages/not-authorized.png" alt="graphic-not-authorized" class="mx-auto mb-4">
            <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">You are not authorized!</h1>
            <p class="mt-3">This page is only available to organizational personnels who are authorized.</p>
            <vs-button size="large" @click="$router.go(-2)">Back to Home</vs-button>
        </vs-col>
    </vs-row>
</template>

<script>
export default {
    name: 'Unauthorized'
}
</script>